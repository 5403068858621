.icon-warning {
  color: #fff;
  background-color: #e91e63 !important;
}

.icon-improvement {
  color: #fff;
  background-color: #4caf50 !important;
}

.icon-recommendation {
  color: #fff;
  background-color: #2196f3 !important;
}

.icon-high {
  color: #fff;
  background-color: #e91e63 !important;
}

.icon-medium {
  color: #fff;
  background-color: #eab05d !important;
}

.icon-low {
  color: #fff;
  background-color: #4caf50 !important;
}
