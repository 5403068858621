html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

/* 
* Bubble Map
*/
.node {
  cursor: pointer;
}

svg.bubblemap text {
  pointer-events: none;
}

a {
  cursor: pointer;
  /* Disable browser styles in favor of Material UI's */
  color: inherit;
  text-decoration: none;
}

@media (max-width: 1298px) {
  #topbar {
    display: none;
  }
}

@media (max-width: 1298px) {
  #searchInput {
    display: none;
  }
}

.node-label {
  font-size: 10px;
  padding: 1px 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  color: lightgray;
}

.node {
  transition: fill 0.25s;
  opacity: 0.25;
}

.node:hover {
  opacity: 1;
}

.node.hover text {
  opacity: 0;
}

.node.highlight {
  fill: #41ffdc;
}

.node.node--mca.highlight {
  fill: rgb(216, 0, 193);
}

.node.node--root.highlight {
  fill: #a7ffef;
}

.node--root {
  fill: #eceff0;
  opacity: 1;
}

.node--branch-inner {
  fill: #00b4c6;
}

.node--branch-inmost {
  fill: #383d3a;
}

.node--branch-outer {
  fill: rgb(169, 234, 234);
}

.node--leaf {
  fill: white;
}

.node--leaf.node--mca {
  fill: #3b73c7;
}

.node.node--branch-outer:hover,
.eight .node.node--branch-outer {
  stroke-width: 1px;
  stroke: "#00b4c6";
}

.label {
  font-size: 9px;
  text-anchor: middle;
  stroke: #fff;
  stroke-width: 1.5px;
  paint-order: stroke;
  fill: #000;
  color: #000;
  cursor: pointer;
}

.grid-lines line {
  stroke: gray;
  stroke-opacity: 0.2;
}

/* .label,
.node--root,
.node--leaf,
text {
  pointer-events: none;
  cursor: default;
} */

.sixteen .node--leaf,
.sixtyfour.node--leaf {
  pointer-events: inherit;
}

.root .node.node--branch-inner,
.root .node.node--leaf {
  pointer-events: none;
}

.eight .node.node--leaf {
  pointer-events: none;
}

.bubblemap {
  transition: opacity 0.4s ease-out;
}

.bubblemap.node-view {
  opacity: 0;
}

.bubblemap.hidden {
  display: none;
}

.loadingImg {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.loadingImg.show {
  opacity: 1;
}

/* 
* Resilience Spinner 
*/

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.7;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.subnet-icon {
  animation: fillfade1 2.1s ease-in-out infinite;
}

.mca-icon {
  stroke-dasharray: 187;
  stroke-width: 1px;
  animation: mcafillfade 2.1s ease-out infinite;
}

.filter-icon {
  stroke-dasharray: 187;
  stroke-width: 4px;
  animation: fillfade 2.1s ease-in-out infinite, dash 1.7s ease-in-out infinite,
    strokefade 0.7s ease-out infinite;
}

@keyframes strokefade {
  0% {
    stroke: #00b4c6;
  }
  26.5% {
    stroke: "rgb(169, 234, 234)";
  }
  100% {
    stroke: #00b4c6;
  }
}

@keyframes fillfade1 {
  0% {
    fill-opacity: 0.92;
  }
  26.5% {
    fill-opacity: 0.7;
  }
  100% {
    fill-opacity: 0.92;
  }
}

@keyframes fillfade {
  0% {
    fill: #0a8f99;
  }
  26.5% {
    fill: #074347;
  }
  100% {
    fill: #0a8f99;
  }
}

@keyframes mcafillfade {
  0% {
    fill: #3b73c7;
  }
  26.5% {
    fill: #3b73c7;
  }
  100% {
    fill: #3b73c7;
  }
}

/* 
* Resilience 
*/

.node .selected {
  stroke: yellow;
}

.brush .extent {
  fill-opacity: 0.1;
  stroke: #fff;
  shape-rendering: crispEdges;
}

/* 
* Resilience Internet Icon 
*/

@keyframes internet {
  0% {
    fill: #02292c;
  }
  46% {
    fill: #4b0043;
  }
  77% {
    fill: #02292c;
  }
}

.internet {
  stroke: white;
  stroke-dasharray: 2;
  stroke-width: 35;
  animation: internet 2s ease-in-out infinite;
}

/*
* Resilience Node Fill Colors
*/
.critical-fill-color {
  stop-color: #3b73c7;
}

.non-critical-fill-color {
  stop-color: var(--color-key-blue);
}

/*
* Risk Scatterplot
*/
#scatterplot circle {
  opacity: 0.75;
}
#scatterplot circle.brushed {
  opacity: 1;
}
